<template>
  <v-container
    class="pa-0"
    :style="'background: ' + sectionBgColor"
    fill-height
    fluid
  >
    <v-overlay v-model="countdown">
      <div style="position: relative">
        <div style="position: absolute; top: -40vh; transform: translate(-50%)">
          <h1 style="font-size: 300px">
            {{ countdown_timer }}
          </h1>
        </div>
      </div>
    </v-overlay>
    <v-row
      :style="!recordStarted ? '' : 'position: absolute; opacity: 0'"
      justify="center"
    >
      <v-col v-if="!startBtn && firstPage" cols="12" md="6">
        <v-card
          class="rounded-xl pa-10 mt-5 record-card"
          :style="'background: ' + brandColor"
          flat
        >
          <div class="center-logo">
            <router-link to="/">
              <img
                :key="app_domain_logo_key"
                :src="app_domain_logo_url"
                alt="Recording App Logo"
                class="logo-size"
              />
            </router-link>
          </div>
          <div class="recording-title">
            <strong :style="'color: ' + headingTextColor"
              >Start Your Recording</strong
            >
          </div>
          <small v-if="$route.query.support" class="grey--text">
            Your screen recording will be
            {{ !$route.query.support ? "submitted through" : "sent to" }}
            <span class="primary--text">
              {{ $route.query.support || "public form" }}
            </span>
          </small>
          <div class="mt-3">
            <v-row justify="center" class="">
              <v-switch
                :disabled="screenAndCam || toggleScreen"
                flat
                inset
                v-model="toggleCamera"
                label="Camera"
              ></v-switch>
            </v-row>
            <v-row justify="center" class="mt-n6 ml-n5 d-none d-md-flex">
              <v-switch
                :disabled="toggleCamera || screenAndCam"
                flat
                inset
                v-model="toggleScreen"
                label="Screen"
              ></v-switch>
            </v-row>
            <v-row
              v-if="$vuetify.breakpoint.mdAndUp"
              justify="center"
              class="mt-n6"
              style="padding-left: 43px"
            >
              <v-switch
                :disabled="toggleCamera || toggleScreen"
                flat
                inset
                v-model="screenAndCam"
              >
                <template v-slot:label>
                  <span>Screen + Cam </span>
                  <small
                    style="position: absolute; min-width: 200px; left: 60px"
                    v-if="toggleScreen && firefox"
                  >
                    (Not Supported on Firefox)
                  </small>
                </template>
              </v-switch>
            </v-row>
            <v-row justify="center">
              <screen-resolution
                :selected="video_quality"
                :disabled="record_btn === 'Stop Recording'"
              ></screen-resolution>
            </v-row>
            <v-row justify="center" class="my-0">
              <v-btn
                :disabled="record_btn === 'Stop Recording'"
                text
                class="secondary text-capitalize px-2 custom-mic"
                @click="toggleMic = !toggleMic"
              >
                <v-icon size="17px" color="black" class="mr-1">
                  mdi-microphone
                </v-icon>
                <small class="mic-chip">
                  Default - Microphone
                  <v-chip label x-small :class="microphoneSwitchClasses">
                    {{ toggleMic ? "on" : "off" }}
                  </v-chip>
                </small>
              </v-btn>
            </v-row>
          </div>
          <div class="d-flex justify-center mt-4">
            <v-btn
              :id="record_btn !== 'Stop Recording' ? 'captureBtn' : ''"
              @click="
                record_btn !== 'Stop Recording'
                  ? startRecording()
                  : stopRecording()
              "
              class="rounded-xl capitalize px-10 custom-start-btn"
              large
              :disabled="!toggleScreen && !toggleCamera && !screenAndCam"
              :color="accentColor"
              :style="'color: ' + buttonTextColor"
            >
              <v-icon v-if="record_btn === 'Stop Recording'" class="blink">
                mdi-square
              </v-icon>
              {{ record_btn }}
            </v-btn>
          </div>
          <div class="mt-10">
            <strong class="capitalize error--text">protect your privacy</strong>
            <small class="d-block" :style="'color: ' + headingTextColor">
              Before you start recording, you'll be prompted which screen or
              window you would like to record. You can preview your recording on
              this page before you decide to upload it.
            </small>
          </div>
          <v-row class="mt-3">
            <v-card-text
              @click="goBack"
              class="text-center grey--text pa-4 pointer"
              style="visibility: hidden"
            >
              <v-icon>mdi-keyboard-backspace</v-icon>
              Go back
            </v-card-text>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        v-if="startBtn"
        cols="12"
        md="6"
        style="position: absolute; top: 10px"
      >
        <v-card class="white py-4 px-7 rounded-xl">
          <div class="center-logo">
            <router-link to="/">
              <img
                :key="app_domain_logo_key"
                :src="app_domain_logo_url"
                alt="Recording App Logo"
                class="logo-size"
              />
            </router-link>
          </div>
          <div class="mt-4">
            <strong class="capitalize text-h5">
              Recording will start once you allow access to your screen...
            </strong>
            <small class="d-block grey--text mt-6">
              Please follow your browser instructions
            </small>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        :class="!shareScreen ? 'showScreen' : 'hideScreen'"
      >
        <video-js :settings="settings" :mic="toggleMic"></video-js>
      </v-col>
    </v-row>
    <v-row
      class="mt-4"
      justify="center"
      :style="
        !toggleScreen && recordStarted && toggleCamera
          ? ''
          : 'opacity: 0; position: absolute; z-index: -9999'
      "
    >
      <v-col cols="12">
        <v-btn
          v-if="!toggleCamera"
          @click="stopRecording"
          class="white--text capitalize px-10 float-right mr-16"
          :color="accentColor"
          large
        >
          <v-icon v-if="record_btn === 'Stop Recording'" class="blink">
            mdi-square
          </v-icon>
          {{ record_btn }}
        </v-btn>
        <v-btn
          v-if="toggleCamera"
          @click="stopRecording"
          class="white--text capitalize px-10 float-right mr-16"
          color="red"
          large
        >
          <v-icon v-if="record_btn === 'Stop Recording'" class="blink">
            mdi-square
          </v-icon>
          {{ record_btn }}
        </v-btn>
      </v-col>
      <v-col cols="10" :style="!recordStarted ? 'display: none' : ''">
        <div>
          <video
            :width="
              screenClass
                ? $vuetify.breakpoint.smAndDown
                  ? '100%'
                  : ''
                : '100%'
            "
            playsinline
            id="webCamera"
            :style="toggleScreen ? screenClass : ''"
            :controls="!recordStarted"
          ></video>
        </div>
      </v-col>
    </v-row>
    <button style="visibility: hidden" id="camBtn">Button</button>
  </v-container>
</template>

<script>
// import DomainLogo from "../../components/Domains/DomainLogo";
import VideoJs from "../../components/VideoJs";
import ScreenResolution from "../../components/ScreenResolution";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    VideoJs,
    ScreenResolution,
    // DomainLogo,
  },
  name: "RecordScreen",
  data() {
    return {
      toggleMic: true,
      toggleScreen: false,
      toggleCamera: false,
      screenAndCam: false,
      firstPage: true,
      startBtn: false,
      shareScreen: false,
      finished: false,
      showScreen: {
        opacity: 0,
        position: "absolute",
      },
      record_btn: "start recording",
      disabled: false,
      screenClass: "pointer-events: none; opacity: 0; position: absolute",
      recordStarted: false,
      firefox: navigator.userAgent.includes("Firefox"),
      logo: null,
      timer: null,
      video_quality: 5000000,
      startCountDown: false,
      countdown: false,
      countdown_timer: 3,
      countdown_id: null,
      app_domain_logo_url: require("../../assets/logo.png"),
      app_domain_logo_key: 0,
    };
  },
  created() {
    this.timer = setTimeout(() => {
      this.logo = this.settings?.logo_url;
      clearTimeout(this.timer);
    });
    // if (window.location.href.indexOf(process.env.VUE_APP_URL) === -1) {
    //   this.getUser();
    // }
    this.getUser();
    this.$root.$on("video-quality", (data) => {
      this.video_quality = data;
    });
  },
  mounted() {
    this.countdown_id = setInterval(() => {
      if (this.countdown && this.countdown_timer > 0) {
        this.countdown_timer -= 1;
        if (this.countdown_timer <= 0) {
          this.countdown = false;
          clearInterval(this.countdown_id);
        }
      }
    }, 1200);
  },
  computed: {
    ...mapGetters({
      settings: "settings/settings",
      user: "auth/user",
    }),
    accentColor() {
      return this.settings?.accent_color || "primary";
    },
    brandColor() {
      return this.settings?.brand_color || "#FFFFFF";
    },
    sectionBgColor() {
      return this.settings?.section_bg_color || "#5A67FB";
    },
    headingTextColor() {
      return this.settings?.heading_text_color || "#000000";
    },
    buttonTextColor() {
      return this.settings?.button_text_color || "#FFFFFF";
    },
    microphoneSwitchClasses() {
      return {
        primary: this.toggleMic,
        red: !this.toggleMic,
        "px-1": true,
        "switch-on-color": this.toggleMic,
        "switch-off-color": !this.toggleMic,
      };
    },
  },
  methods: {
    ...mapActions({
      get_user: "recordings/get_user",
    }),
    goBack() {
      this.$router.go(-1);
    },
    startRecording() {
      this.startBtn = true;
      this.firstPage = false;
      this.shareScreen = true;
    },
    stopRecording() {
      this.$root.$emit("stop-recording");
    },
    clickCamBtn() {
      document.getElementById("camBtn").click();
    },
    async getUser() {
      try {
        const response = await this.get_user(window.location.hostname);
        if (response.data.favicon_url !== null) {
          const defaultDomain = response.data;

          localStorage.setItem("favicon", defaultDomain.favicon_url);
          localStorage.setItem("tagline", defaultDomain.tagline);
          localStorage.setItem("domain_logo", defaultDomain.domain_logo_url);
          // Application Logo
          this.app_domain_logo_url = response.data.domain_logo_url;

          this.app_domain_logo_key += 1;

          let checkDefault = defaultDomain.tagline !== undefined ? true : false;
          localStorage.setItem("is_custom_domain", checkDefault);
        } else {
          localStorage.removeItem("favicon");
          localStorage.removeItem("tagline");
          localStorage.removeItem("domain_logo");
          localStorage.setItem("is_custom_domain", false);
        }

        if (!this.user.email) {
          this.$store.commit("auth/USER_DATA", response.data.user);
        }
        // this is longer needed! Been replaced with `domain_logo`
        this.logo = response?.data?.settings?.logo_url;

        this.$store.commit("settings/SETTINGS", response.data.branding);
      } catch (e) {
        console.warn(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-size {
  height: 50px;
  width: 150px;
  margin-bottom: 20px;
}
.showScreen {
  opacity: 0;
  position: absolute;
  z-index: -999;
}
.hideScreen {
  opacity: 1;
  z-index: 1;
}
.blink {
  animation: fadeinout 1.5s infinite;
}
@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.custom-mic {
  border: 1px solid #eee !important;
  width: 250px !important;
  height: 40px !important;
}
.custom-start-btn {
  width: 270px !important;
  height: 60px !important;
  border-radius: 50px !important;
  font-size: 17px !important;
}
.switch-off-color {
  color: #fff !important;
}
.switch-on-color {
  color: #fff !important;
}
.mic-chip {
  color: #000;
}
.recording-title {
  font-size: 24px !important;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50 !important;
}
.record-card {
  padding-bottom: 0px !important;
  height: 650px !important;
}
</style>
